import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Button,
  ColorPalette,
  Fonts,
  HyperLink,
  LinkTypes,
  StyledCaption2,
  StyledHeadline1,
} from "yuka";

import { QUERY_KEYS, fetchQueryResponse } from "api";
import downloadFileResponse from "utils/downloadFileResponse";

import { SPV_TYPE_MASTER } from "./constants";

const StyledHeader = styled.div`
  border-bottom: 1px solid ${ColorPalette.white15};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeaderName = styled.div`
  display: flex;
  margin-top: 16px;
  padding-bottom: 24px;
  flex-direction: column;
`;

const StyledHeaderFirstRow = styled.div`
  margin-bottom: 4px;
`;

const SPVProfileHeader = ({ spv, parent }) => {
  const downloadCsv = () => {
    fetchQueryResponse(QUERY_KEYS.SPV.detail(spv.id, ["download-csv"])).then(data => {
      downloadFileResponse(data);
    });
  };
  return (
    <>
      <StyledCaption2>
        <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
          SPVs
        </HyperLink>
        {parent && (
          <>
            <span> / </span>
            <HyperLink url={`/spvs/${parent.apiId}/`} linkType={LinkTypes.LOCAL_LINK}>
              {parent.name}
            </HyperLink>
          </>
        )}
        <span> / {spv.name}</span>
      </StyledCaption2>
      <StyledHeader>
        <StyledHeaderName>
          <StyledHeaderFirstRow>
            <StyledHeadline1>{spv.name}</StyledHeadline1>
          </StyledHeaderFirstRow>
          {spv.spvType !== SPV_TYPE_MASTER && (
            <span>
              <StyledCaption2>
                {"Representing shares in: "}
                <Fonts.Caption2theme80>{spv.companyName}</Fonts.Caption2theme80>
              </StyledCaption2>
            </span>
          )}
        </StyledHeaderName>
        <Button onClick={downloadCsv}>Download SPV Spreadsheet (Beta)</Button>
      </StyledHeader>
    </>
  );
};

SPVProfileHeader.propTypes = {
  spv: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    spvType: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    parentName: PropTypes.string,
  }).isRequired,
  parent: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
  }),
  company: PropTypes.shape({
    apiId: PropTypes.string,
  }),
};

export default SPVProfileHeader;
